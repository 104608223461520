@import './styles/colors.css';

.user-account {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding-right: 1.625rem;
    height: 100%;
}

.user-initial {
    display: inline-block;
    font-size: 1.5rem;
    width: 2.8125rem;
    height: 2.8125rem;
    line-height: 2.8125rem;
    text-align: center;
    border-radius: 50%;
    background: var(--skeptic-color);
    color: var(--shamrock-color);
}
