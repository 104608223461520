@import '../styles/colors.css';

.submit-button {
    width: auto;
    padding: 1rem 5rem;
    border: none;
    border-radius: 0.25rem;
    color: var(--white-color);
    background-color: var(--manatee-color);
    text-align: center;
    font-size: 1rem;
    line-height: 1.21rem;
    margin-top: 0.5rem;
}

.login-form {
    text-align: center;
    flex-grow: 1;
    padding: 0 9.375rem;
}

.header{
    font-weight: 600;
    font-size: 2rem;
    line-height: 130%;
}

.subheader{
    font-weight: 400;
    font-size: 1rem;
    line-height: 175%;
    color: var(--manatee-color);
}

.login-form-field {
    margin-top: 1rem;
    margin-bottom: 4.375rem;
}

.login-form-wrapper {
    float: left;
    height: 100vh;
    width: 50vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right:0;
}

.login-background {
    float: right;
    width: 50vw;
    height: 100vh;
}
