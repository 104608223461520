.sticky-header {
  position: sticky;
  top: 0;
  z-index: 10;
}

.sticky-header th:first-child {
  width: 25rem;
}

.staff-row {
  height: 3.0625rem;
}

.staff-row td:first-child {
  height: inherit;
  width: 25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  font-weight: 500;
  font-size: 14;
  color: '#232D42';
}
