:root {
    --lightgrey-color: #E9ECEF;
    --shamrock-color: #31C67F;
    --mercury-color: #E3E3E3;
    --manatee-color: #8A92A6;
    --white-color: #FFFFFF;
    --ebonyclay-color: #232D42;
    --dodgerblue-color: #349BFB;
    --skeptic-color: #D5EBDF;
    --whitesand-color: #F5F5F5;
}
