.assign-member-form {
  height: inherit;
  overflow: inherit;
  transition:all 0.3s ease;
}

.assign-member-form.hide {
  height: 0;
  overflow: hidden;
}
