.month-event-name {
    color: #000000;
    width: 100%;
    border-radius: 0.3125rem;
    padding: 0 0.1875rem 0 0.1875rem;
    margin-right: 0.3125rem;
}

.month-event {
    display: inline-flex;
    align-items: center;
    margin-bottom: 0.3125rem;
}
