@import './components/styles/colors.css';
@import './components/styles/fonts.css';

.App {
  text-align: center;
}

.top-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5%;
  border: solid 0.0625rem var(--lightgrey-color);
  border-left: 0;
}

.always-open-accordion-header {
  pointer-events: none;
}

.always-open-accordion-header .accordion-button:after {
  display: none;
}

.search-box {
  width: 15.8vw;
  margin-left: 1%;
}

.main-container {
  height: calc(100vh - 4.9125rem);
  display: flex;
  flex-direction: row;
}

.side-bar {
  height: 100%;
  padding: 0 1rem;
  border: solid 0.0625rem var(--lightgrey-color);
  overflow-y: auto;
  width: 16vw;
}

.component {
  height: 100%;
  position: relative;
  background-color: var(--whitesand-color);
  overflow-y: auto;
  width: 84vw;
}

.navbar {
  background-color: var(--white-color);
  position: sticky;
  border-bottom: 0.0625rem solid var(--lightgrey-color);
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 4.8125rem;
}

.row-nowrap {
  white-space: nowrap;
  overflow: auto;
}

.no-scroll-display::-webkit-scrollbar {
    display: none;
}

.flex-row {
  display: flex;
  flex-direction: row;
  margin-top: 0.625rem;
}

.nav-item .nav-link {
    border: none;
    color: var(--ebonyclay-color);
    font-size: 1rem;
    line-height: 1.1875rem;
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link[aria-selected=true], .nav-tabs .nav-link:hover {
    border-bottom: 0.1875rem solid var(--shamrock-color);
    color: var(--shamrock-color);
    font-weight: 700;
}

body {
  font-family: 'Inter', sans-serif;
  width: 100vw;
  height: 100vh;
}

h6 {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: var(--medium-font-size);
  padding-right: 2rem;
  margin: 0;
}

h5 {
  font-size: 1.5rem;
  line-height: 1.83125rem;
  color: var(--white-color);
  font-family: 'Inter', sans-serif;
  padding: 0;
  margin: 0;
}

h5 > div {
  font-family: inherit;
  margin: inherit;
  padding: inherit;
  font-weight: 300;
  font-size: 0.875rem;
  color: inherit;
}

h4 {
  color: #ADB5BD;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 1rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h3 {
  font-weight: 700;
  font-size: clamp(1rem, 1.25vw, 1.375rem);
  margin-left: 0%;
  line-height: 1.8125rem;
}

.listview-table {
  width: 156.25rem;
}

.listview-table.case-history-table{
  min-width: 100rem;
  width: 100%;
}

.listview-table th {
  white-space: nowrap;
  background-color: var(--lightgrey-color);
}

.listview-table td {
  background-color: var(--white-color);
}

.listview-table th:first-child, .listview-table td:first-child {
    position: sticky;
    left: 0;
    width: 4.6875rem;
}

.listview-table.case-by-case-table th:first-child, .listview-table.case-by-case-table td:first-child {
  position: sticky;
  left: 0;
  width: 7.75rem;
}

.listview-table th:nth-child(2), .listview-table td:nth-child(2) {
    position: sticky;
    left: 4.6875rem;
    width: 15.625rem;
}

.listview-table.case-by-case-table th:nth-child(2), .listview-table.case-by-case-table td:nth-child(2) {
  position: sticky;
  left: 7.75rem;
  width: 15.625rem;
}

.listview-table th:nth-child(3), .listview-table td:nth-child(3) {
    position: sticky;
    left: 20.3125rem;
    width: 1.875rem;
}

.listview-table.case-by-case-table th:nth-child(3), .listview-table.case-by-case-table td:nth-child(3) {
  position: inherit;
  left: inherit;
  width: inherit;
}

.listview-table th:first-child, .listview-table th:nth-child(2), .listview-table th:nth-child(3) {
    background-color: var(--lightgrey-color);
}

.listview-table > thead {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: var(--lightgrey-color);
}

.table-wrapper {
  position: absolute;
  top: 12.125rem;
  left: 2.5rem;
  right: 2.5rem;
  bottom: 6.25rem;
  overflow: auto;
}

.table-loading-container {
  position: absolute;
  top: 12.125rem;
  left: 2.5rem;
  right: 2.5rem;
  bottom: 6.25rem;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tab-content-wrapper {
  position: absolute;
  top: 15.875rem;
  left: 2.5rem;
  right: 2.5rem;
  bottom: 6.25rem;
  overflow: auto;
}
.tab-calendar-wrapper {
  position: absolute;
  top: 15.875rem;
  left: 2.5rem;
  right: 2.5rem;
  bottom: 0rem;
}

.dropdown-toggle::after {
  content: none;
}

.dropdown-toggle:focus {
  box-shadow: none;
}

.dropdown-toggle {
  height: 4.8125rem;
}

[aria-labelledby="account-menu"] {
  width: calc(100% + 3.2125rem);
  margin-left: -3.2125rem;
  top: calc(100% - 0.4rem) !important;
  color: var(--manatee-color);
  padding: 0;
}

.dropdown-item {
  padding: 0.75rem 1rem;
  color: inherit;
}

.dropdown-item:focus {
  background-color: var(--shamrock-color);
}

.link-icon {
  padding-right: 1rem;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.MuiInputBase-root.MuiInput-root:before  {
  border-bottom: 1px solid var(--lightgrey-color);
}

.MuiInputBase-root.MuiInput-root:hover:before,
.MuiInputBase-root.MuiInput-root:after {
  border-bottom: 2px solid var(--shamrock-color);
}

.MuiFormLabel-root.MuiInputLabel-root.Mui-focused,
.MuiFormLabel-root.MuiInputLabel-root {
  color: var(--manatee-color);
}

.rbc-month-row {
  overflow: visible !important;
}

.float-right {
  float: right;
}

form .form-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 1rem 1rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  background-color: var(--white-color);
  gap: 0.5rem;
}

form .form-footer .form-footer-btn-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

form .form-footer-btn-group .form-footer-btn-group-right {
  margin-right: -1em;
}

form .form-body {
  padding-bottom: 5rem;
}

.sort-icon {
  margin-left: 0.5rem;
  font-size: 1.7rem;
  vertical-align: top;
  cursor: pointer;
}

.nav-link {
  text-decoration: none;
  color: var(--manatee-color);
  border-radius: 0.25rem;
  min-height: 2.75rem;
  display: flex;
  align-items: center;
  font-size: 1rem;
  padding: 0.5rem 0.75rem;
}

.nav-link.active, .nav-link:target{
  background-color: var(--shamrock-color);
  color: var(--white-color);
}

.nav-link:hover{
  background-color: var(--manatee-color);
  color: var(--white-color);
}

.spinner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
