@import '../styles/colors.css';

.summary {
    display: flex;
    flex-direction: row;
    margin-top: 1.6875rem;
    align-items: center;
    text-align: center;
}

.summary-box {
    aspect-ratio: 1/1;
    width: 6.25rem;
    border-radius: 0.3125rem;
    text-align: center;
    padding-top: 0.5rem;
    margin-right: 1.125rem;
    flex: 0 0 6.5rem;
}

.summary-box .summary-box-title {
    font-size: 1rem;
    margin-bottom: 0;
}

.summary-box .summary-box-content {
    font-size: 2rem;
    font-weight: bold;
}

.summary-box.green {
    border: 0.125rem solid var(--shamrock-color);
    color: var(--shamrock-color);
}

.summary-box.green-invert {
    color: var(--white-color);
    background-color: var(--shamrock-color);
}

.summary-box.black {
    border: 0.0625rem solid var(--mercury-color);
    color: #232D42;
}

.current-roster-table {
    padding-right: 1.5rem;
    padding-top: 1.875rem;
}

.current-roster-table tr {
    height: 0.626rem !important;
}

.current-roster-table p {
    font-weight: bold;
    text-transform: uppercase;
}

.current-roster-table table {
    border: 1px solid #f5f5f5;
    border-radius: 10px;
}

.case-name-tag {
    border-radius: 0.3125rem;
    padding: 0.1875rem 1.125rem;
    display: inline-block;
}

.active-case-box {
    box-sizing: border-box;
    display: inline-block;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 1.25rem 1rem;
    border: 0.0625rem solid var(--mercury-color);
    border-radius: 0.25rem;
    margin-top: 2.5rem;
    margin-right: 1.875rem;
}

.active-case-names {
    height: auto;
    width: 100%;
}

.active-case-name {
    margin-bottom: 0.625rem;
    background-color: var(--lightgrey-color);
}

.active-case-principal {
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5625rem;
}

.active-case-count {
    color: var(--manatee-color);
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5625rem;
    margin-bottom: 0.625rem;
}

.grey-large-text {
    color: var(--manatee-color);
    font-size: 2rem;
    display: inline;
    margin: 0.1875rem;
}

.detail {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.626rem;
}

.detail > div:nth-child(2), .detail > div:nth-child(4), .detail > div:nth-child(6), .detail > div:nth-child(8) {
    display: inline;
    height: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 0.626rem;
}

.detail > div:first-child, .detail > div:nth-child(3), .detail > div:nth-child(5), .detail > div:nth-child(7) {
    color: var(--manatee-color);
    font-size: 1.5rem;
    display: inline;
    margin: 0.1875rem;
}

.dbcontainer {
    position: absolute;
    top: 12.125rem;
    bottom: 0;
    padding: 0 2.5rem;
    width: 100%;
}

.dashboard-panel {
    background-color: var(--white-color);
    height: 100%;
    padding: 1.5rem;
    margin: -0.1875rem;
}

.dashboard-panel::-webkit-scrollbar {
    display: none;
}

.dbcontainer .dashboard-panel {
}

.capacity-table {
    table-layout: fixed;
    overflow: hidden;
}

.capacity-table th {
    text-align: center;
    font-weight: normal;
}

.capacity-table td {
    border: 0.0625rem solid var(--mercury-color);
    overflow: hidden;
}

.capacity-items {
    vertical-align: top;
    font-size: 0.875rem;
    line-height: 1.3125rem;
}

.capacity-item {
    margin-bottom: 0.25rem;
}

.capacity-item-score {
    color: var(--dodgerblue-color);
}

.capacity-header {
    font-weight: 500 !important;
    font-size: 0.875rem;
    line-height: 1.0625rem;
}

.cases-wrapper {
    display: 'inline-block'
}
